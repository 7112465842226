import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper"
import { Button } from "@components/atoms/Button"
import { Pill } from "@components/atoms/Pill"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { HeroLogo } from "@components/svg/HeroLogo"
import { IconPlay } from "@components/svg/Icons"
import { defineBlock, EditableText } from "eddev/blocks"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import Vimeo from "@vimeo/player"

export const meta: BlockMeta = {
  title: "Home Video",
}

export default defineBlock("home/video", (props) => {
  const [playing, setPlaying] = useState(false)
  const player = useRef<Vimeo | null>(null)
  const videoRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!props.vimeoUrl || !videoRef.current) return
    player.current = new Vimeo(videoRef.current, {
      url: props.vimeoUrl,
      autoplay: false,
      loop: false,
      byline: false,
      // @ts-ignore
      colors: ["222222", "EBBE32", "EBBE32", "222222"],
      portrait: false,
      responsive: true,
      title: false,
      vimeo_logo: false,
      pip: false,
    })

    player.current.on("ended", () => {
      setPlaying(false)
    })

    return () => {
      player.current?.off("ended")
      player.current?.destroy()
    }
  }, [videoRef])

  useEffect(() => {
    if (playing) {
      player.current?.play()
    } else {
      player.current?.pause()
    }
  }, [playing])

  return (
    <BlockWrapper toolbarState="live-index">
      <BlockItem>
        <div className="flex justify-center items-center w-full">
          <div ref={videoRef} className="absolute w-full h-full left-0 top-0 flex justify-center items-center [&>div]:w-full" />
          {!playing && props.video?.mediaItemUrl && (
            <video src={props.video.mediaItemUrl} className="absolute w-full h-full left-0 top-0 object-cover block" loop muted playsInline autoPlay />
          )}

          <div className="w-full relative flex flex-col h-[calc(56.25vw-(var(--spacing-6)*2))] justify-center items-center pointer-events-none">
            <Pill className="absolute left-0 top-0">
              <EditableText
                as="span"
                className=""
                store="video-pill"
                inlineToolbar={false}
                defaultValue="Sir Graham Henry explains"
                placeholder="Sir Graham Henry explains"
              />
            </Pill>
            {!playing && (
              <>
                <div onClick={() => setPlaying(true)} className="absolute left-0 top-0 w-full h-full cursor-pointer pointer-events-auto" />
                <Button icon={<IconPlay style={{ width: "1.5em", height: "1.5em" }} />} className="w-fit pointer-events-auto relative hover:bg-orange-light" onClick={() => setPlaying(true)}>
                  <EditableText
                    as="span"
                    className=""
                    store="video-button"
                    inlineToolbar={false}
                    defaultValue="Watch"
                    placeholder="Watch"
                  />
                </Button>
              </>
            )}
          </div>
        </div>
      </BlockItem>
    </BlockWrapper>
  )
})